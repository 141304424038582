import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import { FullPreferenceDetails } from 'components/CheckDetails/TieredCheckDetailsPage';
import InterstitialPage from 'components/InterstitialPage';
import { navigatePreservingQuote } from 'helpers/navigation';
import { quoteAndBuyRoutes, renewalRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { isRenewal, useQuote } from 'state/quote/quote';
import useUpdatePreferences from 'state/quote/useUpdatePreferences';
import { CsMeta } from 'types/contentStack';

type PaymentLoadingProps = {
  paymentLoadingContent: {
    meta: CsMeta;
    heading: string;
    subheading: string;
  };
  fullPreferences: FullPreferenceDetails | undefined;
  userLoggedIn: boolean | undefined;
  hasAccount: boolean | null | undefined;
};

const PaymentLoadingPage: React.FC<PaymentLoadingProps> = ({
  paymentLoadingContent: { heading, subheading, meta },
  userLoggedIn,
  hasAccount,
  fullPreferences,
}) => {
  const quote = useQuote();

  const [requestStarted, setRequestStarted] = useReducer(() => true, false);

  const { requestHandler } = useApiRequestHandler();
  const updatePreferences = useUpdatePreferences(requestHandler);

  const updatePreferencesAndMoveNext = useCallback(async (): Promise<void> => {
    if (quote && fullPreferences) {
      const updatedQuote = await updatePreferences(quote, fullPreferences);

      if (updatedQuote) {
        if (isRenewal(quote)) {
          navigate(renewalRoutes.payment(quote.policyNumber), {
            state: { detailsValid: true },
            replace: true,
          });
          return;
        }

        if (userLoggedIn) {
          navigatePreservingQuote(
            quoteAndBuyRoutes.payment,
            undefined,
            {
              detailsValid: true,
            },
            true
          );
          return;
        }

        navigatePreservingQuote(
          quoteAndBuyRoutes.payment,
          undefined,
          {
            detailsValid: true,
            hasAccount,
            fullPreferences,
          },
          true
        );
      }
    }
  }, [fullPreferences, hasAccount, quote, updatePreferences, userLoggedIn]);

  useEffect(() => {
    if (!requestStarted && fullPreferences && quote) {
      setRequestStarted();
      updatePreferencesAndMoveNext();
    }
  }, [updatePreferencesAndMoveNext, requestStarted, fullPreferences, quote]);

  return (
    <InterstitialPage
      heading={heading}
      subheading={subheading}
      meta={meta}
      tier={quote?.tieringInfo?.selectedTier}
      data-cy="PaymentLoadingInterstitialPage"
    />
  );
};

export default PaymentLoadingPage;
