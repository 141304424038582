import mapPreferences from 'api/quote/formToQuoteMappings/mapPreferences';
import quoteClient from 'api/quoteClient';
import renewalClient from 'api/renewalClient';
import { RequestHandlerType } from 'helpers/useApiRequestHandler';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import {
  isRenewal,
  Quote,
  Renewal,
  UPDATE_QUOTE,
  UPDATE_RENEWAL,
} from 'state/quote/quote';
import useDispatch from 'state/useDispatch';

const useUpdatePreferences = (
  requestHandler: RequestHandlerType
): ((
  quote: Quote | Renewal,
  preferences: FullPreferenceDetails
) => Promise<Quote | undefined>) => {
  const dispatch = useDispatch();

  const updatePreferences = async (
    quote: Quote | Renewal,
    preferences: FullPreferenceDetails
  ): Promise<Renewal | Quote | undefined> => {
    const mappedPreferences = mapPreferences(preferences);

    return requestHandler(async () => {
      if (isRenewal(quote)) {
        const newQuote = await renewalClient.updatePreferences(
          quote.policyNumber,
          mappedPreferences
        );
        dispatch({
          type: UPDATE_RENEWAL,
          quote: newQuote,
        });
        return newQuote;
      }
      const newQuote = await quoteClient.updatePreferences(
        quote.quoteNumber,
        mappedPreferences
      );
      dispatch({
        type: UPDATE_QUOTE,
        quote: newQuote,
      });
      return newQuote;
    });
  };

  return updatePreferences;
};

export default useUpdatePreferences;
