import { getDocumentsPreferences } from 'api/businessLogic/documents';
import { FullPreferenceDetails } from 'pages/quote/check-your-details';
import { Preferences } from 'state/quote/quote';

const mapPreferences = (preferences: FullPreferenceDetails): Preferences => ({
  contactEmail: preferences.email,
  contactLandline: preferences.mainTelephone,
  receiveExclusiveOffers: preferences.shouldKeepInformed,
  ...getDocumentsPreferences(preferences),
});

export default mapPreferences;
